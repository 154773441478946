.title {
  text-transform: uppercase;
  font-size: 5vw;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  margin: 5%;
}

.header {
  border-bottom: 1px solid #e6e6e6;
}

.header-wrap {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  margin: 0 140px 0 140px;
}

/* .divider {
  margin: 10px;
  width: 100%;
  background-color: grey;
} */

.bmBurgerButton {
  position: "fixed";
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

.bmBurgerBars {
  background: "#373a47"
}

.nav-menu-burger {
  display: none;
}

.nav-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
}

.menu-button {
  width: auto;
  margin: 0 20px 0 20px!important;
  color: #000!important;
  /* background-color: #030E4F; */
}

.menu-button-burger {
  /* width: auto;
  margin: 0 20px 0 20px!important; */
  /* color: #F49F1C!important; */
  margin-top: 10px;
  /* background-color: #030E4F; */
}

.menu-button-burger-testing {
  margin-top: 10px !important;
}

.menu-button-burger .MuiButton-label{
  color: white;
}

.language-list {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 130px;
}

.menu-logout {
  margin-left: auto!important;
  padding: 5px!important;
  max-height: 50px;
}

.nav-menu-xb {
  max-width: 20px;
  margin: 20px;
  display: flex;
  align-items: center;

}

.nav-menu-xb p {
  margin-bottom: 0px;
}

@media (max-width: 1200px) {
  .header-wrap {
    margin: 0 80px 0 80px;
  }
}

@media (max-width: 800px) {
  .header-wrap {
    margin: 0 20px 0 20px;
  }

  .language-list{
    margin-left: auto;
    margin-right: 60px;
  }

  .nav-menu-burger {
    display: block;
  }

  .nav-menu {
      display: none;
  }
}

@media (max-width: 425px) {
  .header-wrap {
    margin: 0px;
  }
  .logo-image{
    max-width: 160px;
  }
  .bm-burger-button {
    right: 26px;
    top: 26px;
  }
  .menu-button-custom .MuiButton-label {
    font-size: 13px;
  }
}

@media (max-width: 375px) {
  .lngFlag {
    display: none;
  }
  .rtc-dropdown .rtc-dropdown-toggle img {
    display: none;
  }
}


@media (max-width: 320px) {
  .logo-image{
    max-width: 150px;
  }
  .bm-burger-button {
    right: 13px!important;
    top: 20px!important;
  }
  .language-list{
    margin-right: 30px;
  }
}

