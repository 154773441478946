.content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 140px 0 140px;
  margin-top: 40px;
}



@media (max-width: 1200px) {
  .content {
    margin: 0 80px 0 0px;
  }
}

@media (max-width: 800px) {
  .content, .content-3 {
    flex-direction: column;
    align-items: center;
    margin: 0 20px 0 20px;
  }
 
}

@media (max-width: 425px) {
  .content {
    margin: 0;
    padding: 20px 5px 0 5px;
    background-color: #f6f8f9;
  }
}


