.footer {
    text-align: center;
    /* margin: 40px 0px 10px 0px; */
    background-color: #030E4F;
    min-height: 200px;
    color: #dfe3e6;
}
.footer-content {
    border-bottom: 1px solid #dfe3e6;
    margin: 0 140px 0 140px;
    display: flex;
    padding: 40px 0;
}
.footer-logo {
    width: 30%;
}
.terms{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
}
.footer-content .footer-logo p {
    color: #dfe3e6;
}

.footer-content .terms a {
    color: #dfe3e6;
}

.rights p {
    color: #dfe3e6;
    padding-top: 15px;
    padding-bottom: 20px;
    margin-bottom: 0;
}

.footer-link {
    margin-bottom: 5px;
}

.XB {
        font-size: 39px;
        color: gold;
}

@media (max-width: 800px) {
    .footer-content {
      flex-direction: column;
      /* align-items: center; */
      margin: 0 20px 0 20px;
    }
    .footer-logo {
        width: 60%;
        margin: auto;
        max-width: 231px;
    }
    .logo-image-footer{
        width: 100%;
    }
    .terms {
        text-align: start;
        margin-left: 15px;
    }
    .rights {
        font-size: 15px;
    }
}

@media (max-width: 425px) {
    .footer-logo {
        padding-bottom: 10px;
    }

    .footer-content {
        padding: 35px 0;
    }
    .rights {
        font-size: 14px;
    }
    .footer-link {
        margin-bottom: 3px;
    }
}

@media (max-width: 375px) {
    .footer-logo {
        width: 70%;
    }
    .rights {
        font-size: 12px;
    }
    .footer-link {
        margin-bottom: 2px;
    }
}

