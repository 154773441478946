.content-stat {
  display: flex;
  flex-direction: column;
}

.question-wrap {
  border: 1px solid black;
  margin: 5px 30px;
  display: flex;
  min-width: 700px;
}

.question-title {
  width: 30%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 4px;
}

.answer-wrap {
  /* margin: auto; */
  width: 11%;
}

.answer-wrap-correct {
  background-color: #C8FFD8;
}

.answer-title, .answer-count {
  padding: 5px;
  margin: 5px;
}

.answer-count {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}