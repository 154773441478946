.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 20vh; */
  text-align: center;
  margin: 40px 300px 80px 300px;
  /* color: unset; */
  background-color: #f6f8f9;
  border-radius:10px;
  padding: 30px;
}
.home-link {
  margin: auto;
}
.login-title {
  margin-top: 50px;
  margin-bottom: 300px;
  font-size: 22px;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}

.login-field {
  margin: 10px!important;
}

@media (max-width: 800px) {
  .login {
      margin: 40px 100px 80px 100px;
  }
}

@media (max-width: 425px) {
  .login {
      margin: 0px;
      border-radius: 0px;
  }
  .login-title {
      font-size: 18px;
  }
}